<script setup lang="ts">
const { allRegions: regions } = await useRegions()

const props = defineProps({
  status: {
    type: Number,
    default() {
      return 0
    },
  },
})

const regionsEnable = computed(() => {
  return regions.filter(
    (item) => item.status == props.status,
  )
})

</script>
<template>
      <SectionHeader title="Book Your Hibachi Party" desc="Select Your Location To Book" />
      <section class="container mx-auto px-4">
        <div class="flex flex-col md:grid md:grid-cols-2 lg:grid-cols-2 gap-4">
          <RegionCard v-for="(region, index) in regionsEnable" :key="index" v-bind="region" />
    </div>
  </section>
</template>
<style scoped></style>
